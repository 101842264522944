import React from 'react';
import './About.scss';

const About = () => {
  return (
	<div className="about">
		<div className="about__text">
			<h4> 
				I like to keep it simple - while still achieving great creativity that leaves a mark.
				Through my creative journey I gathered a variaty of skills & a huge passion for branding.
				My goal is, to create meaningful designs that are thought trough to the last detail & I can 100% stand behind.
				I designed multiple corporate designs, layouts as well as developed many interactive & responsive templates.
			</h4>
			<p className="paragraph-small">
				<img className="about__seperator" src={require('../gfx/small-seperator.svg')} alt="seperator"></img> I'm familiar with:
			</p>
			<p className="tools">
				Adobe Photoshop, Adobe Illustrator, Adobe XD, Adobe InDesign, Adobe AfterEffects, Affinity Designer, Sketch, React, Angular, Jira, Git, Cinema4D, and many more...
			</p>
		</div>
		<img className='portrait' src={require('../gfx/portrait-ks-zig.png')} alt="portrait"></img>
	</div>
  )
};

export default About;
