import React from 'react';
import { Link } from "react-router-dom";
import './Contact.scss';


const Contact = () => {
	return (
		<div className="contact">
			<div className="contact__quote">
				<svg className="contact__quote--spinner" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" >
					<g>
						<g>
							<path className="st0" d="M139.01,46.2l-2-3.8l-10,5.2l-0.7-1.4l10-5.2l-2-3.9l1.3-0.7l4.8,9.1L139.01,46.2z" />
							<path className="st0" d="M138.71,57.9c-0.6,0.4-1.4,0.7-2.4,1l-4.4,1.4l-0.5-1.5l4.4-1.4c0.7-0.2,1.3-0.5,1.8-0.8s0.8-0.7,0.9-1.1
						c0.2-0.5,0.2-1.1,0-1.7s-0.5-1.1-1-1.4s-1.1-0.4-1.7-0.3c-0.3,0-0.7,0.1-1.3,0.3l-4.7,1.5l-0.5-1.5l12.1-3.9l0.4,1.3l-4.2,1.4
						c0.2,0,0.3,0.1,0.5,0.2c0.4,0.2,0.7,0.5,1,0.8c0.3,0.4,0.5,0.7,0.7,1.2c0,0.1,0.1,0.2,0.1,0.4c0.3,0.9,0.3,1.8,0,2.6
						C139.71,57,139.31,57.5,138.71,57.9z"/>
							<path className="st0" d="M134.61,63.8c-0.5,0.6-0.6,1.3-0.5,2.2c0.1,0.6,0.3,1.1,0.7,1.6s0.8,0.7,1.4,0.9l-0.3,1.6
						c-0.9-0.2-1.6-0.7-2.2-1.4c-0.6-0.7-0.9-1.5-1-2.4s0-1.8,0.3-2.5c0.3-0.8,0.8-1.4,1.5-1.9s1.5-0.8,2.5-0.9s1.9-0.1,2.7,0.2
						s1.5,0.7,2,1.3c0.5,0.6,0.8,1.4,1,2.3c0.2,1.5-0.1,2.7-1,3.5s-2.2,1.4-3.8,1.6l-1-7.2C135.81,62.9,135.01,63.3,134.61,63.8z
						M140.11,63.1c-0.6-0.4-1.3-0.6-2.1-0.6l0.8,5.6c0.9-0.2,1.5-0.6,1.9-1.1s0.5-1.2,0.4-2.1C141.01,64.1,140.71,63.5,140.11,63.1z"
							/>
							<path className="st0" d="M141.61,86.7c-0.5,0.6-1.2,1-2,1.2s-1.7,0.3-2.6,0.2c-0.9-0.1-1.8-0.4-2.5-0.9s-1.2-1-1.6-1.7
						s-0.5-1.5-0.3-2.3c0.2-1.3,0.8-2.2,1.7-2.8l-1.1-0.1l0.2-1.4l12.6,1.7l-0.2,1.6l-4-0.6c0.6,0.8,0.9,1.8,0.7,2.9
						C142.41,85.4,142.11,86.1,141.61,86.7z M134.71,85.3c0.6,0.6,1.4,0.9,2.5,1.1c1.1,0.1,1.9,0,2.7-0.4c0.7-0.4,1.2-1,1.3-2
						c0.1-0.9-0.1-1.6-0.7-2.2s-1.4-0.9-2.5-1.1s-2,0-2.7,0.3s-1.1,1-1.3,1.9C133.91,84,134.11,84.8,134.71,85.3z"/>
							<path className="st0" d="M133.21,91.3c-0.7,0.3-1.1,0.9-1.4,1.7c-0.2,0.6-0.2,1.2-0.1,1.7s0.4,1,0.9,1.4l-1,1.3
						c-0.7-0.6-1.1-1.3-1.4-2.2c-0.2-0.9-0.2-1.7,0.1-2.6c0.3-0.9,0.7-1.6,1.4-2.2c0.6-0.5,1.3-0.9,2.2-1c0.8-0.1,1.7-0.1,2.6,0.2
						c1,0.3,1.8,0.8,2.4,1.3c0.6,0.6,1,1.3,1.2,2.1s0.2,1.6-0.1,2.5c-0.4,1.4-1.3,2.3-2.4,2.8c-1.2,0.4-2.6,0.4-4.1-0.2l2.2-7
						C134.71,91,133.91,91,133.21,91.3z M138.51,93.1c-0.3-0.6-0.9-1.1-1.7-1.4l-1.7,5.4c0.9,0.2,1.6,0.1,2.2-0.2s1-0.9,1.3-1.7
						C138.91,94.4,138.81,93.7,138.51,93.1z"/>
							<path className="st0" d="M132.11,106.5c0.5,0.2,1,0.1,1.5-0.2s0.8-0.7,1.1-1.3c0.3-0.5,0.4-1,0.3-1.4c0-0.4-0.2-0.7-0.6-0.9
						c-0.3-0.1-0.5-0.2-0.8,0c-0.3,0.1-0.6,0.3-0.9,0.7l-1,1.1l-0.3,0.3c-0.7,0.7-1.3,1.2-1.8,1.5c-0.6,0.2-1.2,0.2-1.9-0.1
						c-0.8-0.4-1.3-1.1-1.5-1.9s0-1.8,0.5-2.8s1.1-1.7,1.9-2.2s1.6-0.6,2.5-0.3l-0.5,1.6c-0.6-0.1-1.1-0.1-1.6,0.3
						c-0.5,0.3-0.9,0.8-1.2,1.4c-0.3,0.5-0.4,1-0.3,1.5s0.3,0.8,0.7,1c0.4,0.2,0.7,0.2,1,0c0.3-0.1,0.6-0.4,1-0.9
						c0.2-0.2,0.4-0.4,0.6-0.6c0,0,0.2-0.3,0.9-0.9c1.1-1.2,2.2-1.5,3.2-1c0.5,0.3,0.9,0.6,1.1,1.1s0.3,1,0.2,1.6
						c-0.1,0.6-0.2,1.2-0.5,1.8c-0.5,1-1.1,1.7-1.9,2.1s-1.6,0.5-2.4,0.2L132.11,106.5z"/>
							<path className="st0" d="M126.11,110.3l-0.5-0.4c-0.2-0.1-0.4-0.3-0.5-0.3c-0.1-0.1-0.3-0.1-0.5-0.1s-0.4,0-0.6,0.1s-0.4,0.2-0.5,0.4
						c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0.3-0.4,0.6-0.5,0.9l-1.1-0.8c0.1-0.3,0.3-0.7,0.6-1.2c0.2-0.3,0.4-0.5,0.6-0.7
						c0.3-0.3,0.6-0.6,0.9-0.7s0.7-0.2,1.1-0.2c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.5,0.3,0.9,0.5l0.6,0.4l3.8,2.6l1.1-1.6l1.1,0.7
						l-1.1,1.6l2.2,1.5l-0.9,1.3l-2.2-1.5l-1.6,2.4l-1.1-0.7l1.6-2.4L126.11,110.3z"/>
							<path className="st0" d="M121.21,125.1l-6.6-6.9l1.1-1.1l6.6,6.9L121.21,125.1z M123.61,127.5l-1.1-1.2l1.1-1.1l1.1,1.2L123.61,127.5z
						"/>
							<path className="st0" d="M114.11,134.9l-7.7-10.2l1.1-0.9l0.7,0.9c0-1.2,0.6-2.1,1.6-2.9c0.7-0.5,1.4-0.8,2.2-0.8s1.5,0.1,2.3,0.5
						c0.7,0.4,1.4,1,2,1.7c0.6,0.8,1,1.6,1.1,2.4c0.2,0.8,0.1,1.6-0.1,2.3c-0.3,0.7-0.7,1.4-1.4,1.9c-0.9,0.7-1.9,1-2.9,0.8l2.5,3.2
						L114.11,134.9z M109.41,125.2c0,0.8,0.4,1.6,1,2.5c0.7,0.9,1.4,1.4,2.1,1.7c0.8,0.2,1.5,0.1,2.2-0.5s1.1-1.3,1.1-2.1
						s-0.3-1.7-1-2.5s-1.4-1.4-2.1-1.6c-0.8-0.2-1.5-0.1-2.3,0.5C109.81,123.7,109.41,124.4,109.41,125.2z"/>
							<path className="st0" d="M103.91,128.6c-0.7-0.3-1.4-0.2-2.2,0.2c-0.6,0.3-1,0.7-1.3,1.1c-0.3,0.5-0.4,1-0.4,1.6l-1.6,0.2
						c-0.1-0.9,0.1-1.7,0.6-2.5c0.4-0.8,1.1-1.4,1.9-1.8c0.8-0.4,1.7-0.6,2.5-0.6s1.6,0.3,2.3,0.8c0.7,0.5,1.3,1.2,1.7,2
						c0.5,0.9,0.7,1.8,0.7,2.6c0,0.9-0.2,1.6-0.6,2.3s-1,1.3-1.9,1.7c-1.3,0.7-2.5,0.8-3.7,0.2c-1.1-0.5-2.1-1.6-2.8-3.1l6.5-3.4
						C105.21,129.4,104.61,128.8,103.91,128.6z M106.41,133.6c0.2-0.7,0.1-1.4-0.2-2.2l-5,2.6c0.5,0.8,1.1,1.2,1.7,1.4s1.3,0.1,2.1-0.3
						C105.81,134.8,106.31,134.2,106.41,133.6z"/>
							<path className="st0" d="M90.01,132.2l0.5,1.4c0.2-0.7,0.5-1.3,1-1.7c0.5-0.5,1.1-0.8,1.9-1.1c0.9-0.3,1.7-0.3,2.4,0
						c0.7,0.3,1.2,0.9,1.5,1.7c0.2,0.6,0.2,1.2,0,1.7s-0.6,1-1.2,1.4c-0.4,0.3-0.9,0.6-1.4,0.9c-0.5,0.3-1.2,0.6-2,1l-0.7,0.3
						c0.3,0.7,0.6,1.2,1.1,1.4s1.1,0.2,1.7,0c1.3-0.4,1.9-1.2,1.9-2.3h1.5c0,0.9-0.2,1.6-0.8,2.3c-0.5,0.7-1.3,1.2-2.3,1.5
						c-0.9,0.3-1.7,0.3-2.4,0.1s-1.3-0.6-1.8-1.3c-0.3-0.4-0.5-0.9-0.7-1.4l-1.8-5.5L90.01,132.2z M91.71,136.7l0.7-0.4
						c0.6-0.3,1-0.5,1.4-0.7s0.7-0.4,1-0.6c0.9-0.6,1.2-1.3,1-2c-0.1-0.4-0.4-0.8-0.8-0.9c-0.4-0.2-0.9-0.2-1.4,0
						c-0.6,0.2-1.1,0.5-1.5,0.9s-0.6,0.9-0.7,1.4C91.21,135,91.31,135.7,91.71,136.7z"/>
							<path className="st0" d="M82.31,140.6c0.2,0.5,0.5,0.9,1,1.1c0.5,0.2,1.1,0.3,1.7,0.1c0.6-0.1,1-0.3,1.3-0.6s0.5-0.6,0.4-1
						c-0.1-0.3-0.2-0.5-0.5-0.7s-0.6-0.3-1.1-0.3l-1.4-0.2h-0.5c-1-0.1-1.7-0.3-2.3-0.6c-0.5-0.3-0.9-0.9-1-1.6
						c-0.2-0.9,0.1-1.7,0.7-2.3s1.5-1,2.5-1.2c1.1-0.2,2.1-0.1,2.9,0.2s1.4,1,1.7,1.9l-1.5,0.5c-0.2-0.5-0.6-0.9-1.1-1.1
						c-0.5-0.2-1.1-0.2-1.8-0.1c-0.6,0.1-1,0.3-1.4,0.6c-0.3,0.3-0.5,0.7-0.4,1.2c0.1,0.4,0.2,0.7,0.5,0.8s0.7,0.3,1.3,0.3
						c0.2,0,0.5,0.1,0.9,0.1c-0.1,0,0.4,0,1.3,0.1c1.6,0.2,2.5,0.9,2.7,2c0.1,0.6,0,1.1-0.3,1.5s-0.7,0.8-1.2,1.1s-1.1,0.5-1.7,0.6
						c-1.1,0.2-2,0.1-2.8-0.3c-0.8-0.4-1.3-1-1.6-1.8L82.31,140.6z"/>
							<path className="st0" d="M64.81,140.6c0,0.6,0.3,1,0.7,1.3c0.4,0.3,1,0.5,1.6,0.6s1.1,0,1.5-0.2s0.6-0.5,0.6-0.9
						c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.4-1-0.6l-1.4-0.5l-0.4-0.1c-0.9-0.3-1.6-0.7-2-1.2c-0.4-0.5-0.6-1-0.6-1.8
						c0.1-0.9,0.5-1.6,1.2-2.1s1.7-0.6,2.8-0.5c1.1,0.1,2,0.4,2.7,1s1.1,1.3,1.2,2.3l-1.6,0.1c-0.1-0.6-0.3-1-0.8-1.4
						c-0.5-0.3-1-0.5-1.7-0.6c-0.6-0.1-1.1,0-1.5,0.2s-0.6,0.6-0.7,1c0,0.4,0.1,0.7,0.3,0.9s0.6,0.4,1.2,0.6c0.2,0.1,0.5,0.2,0.8,0.3
						c-0.1,0,0.3,0.1,1.2,0.5c1.5,0.6,2.2,1.5,2.1,2.7c-0.1,0.6-0.3,1.1-0.6,1.4s-0.8,0.6-1.4,0.8s-1.2,0.2-1.8,0.1
						c-1.1-0.1-2-0.4-2.6-1c-0.7-0.6-1-1.3-1.1-2.2L64.81,140.6z"/>
							<path className="st0" d="M59.11,136.5l0.1-0.6c0.1-0.3,0.1-0.5,0.1-0.6c0-0.2,0-0.3-0.1-0.5s-0.2-0.4-0.3-0.6
						c-0.2-0.2-0.4-0.3-0.6-0.3c-0.1,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.7-0.1-1.1-0.2l0.3-1.3c0.4,0,0.8,0,1.3,0.2c0.3,0.1,0.6,0.2,0.9,0.3
						c0.4,0.2,0.7,0.4,1,0.6c0.3,0.3,0.4,0.6,0.6,1c0.1,0.3,0.1,0.5,0.1,0.8s-0.1,0.6-0.2,1l-0.1,0.5l-1.1,4.5l1.9,0.4l-0.3,1.3
						l-1.9-0.4l-0.6,2.6l-1.5-0.4l0.6-2.6l-2.8-0.7l0.3-1.3l2.8,0.7L59.11,136.5z"/>
							<path className="st0" d="M49.81,129.6l-0.5,1.4c0.6-0.4,1.2-0.6,1.9-0.7c0.7,0,1.4,0.1,2.2,0.4c0.9,0.3,1.5,0.9,1.8,1.6
						s0.4,1.5,0.1,2.3c-0.2,0.6-0.6,1.1-1.1,1.4c-0.5,0.3-1.1,0.4-1.8,0.4c-0.5,0-1.1-0.1-1.7-0.2c-0.6-0.1-1.3-0.3-2.1-0.5l-0.7-0.2
						c-0.2,0.7-0.2,1.3,0,1.8s0.7,0.9,1.3,1.1c1.2,0.5,2.2,0.3,2.9-0.6l1.2,1c-0.5,0.7-1.2,1.1-2,1.3s-1.7,0.1-2.7-0.3
						c-0.9-0.3-1.5-0.8-1.9-1.4s-0.6-1.3-0.6-2.1c0-0.5,0.2-1,0.4-1.5l2.1-5.5L49.81,129.6z M48.21,134.1l0.8,0.2
						c0.6,0.2,1.1,0.3,1.5,0.4s0.8,0.1,1.2,0.2c1.1,0.1,1.7-0.2,2-0.9c0.2-0.4,0.1-0.9-0.1-1.3c-0.2-0.4-0.6-0.7-1.1-0.9
						c-0.6-0.2-1.2-0.3-1.7-0.2s-1,0.3-1.5,0.6C49.01,132.5,48.61,133.1,48.21,134.1z"/>
							<path className="st0" d="M39.51,135c-0.4-0.1-0.7-0.2-1-0.4c-0.2-0.1-0.4-0.3-0.6-0.4l0.7-1.3c0.2,0.2,0.4,0.3,0.6,0.4
						c0.5,0.3,1,0.4,1.5,0.3c0.5-0.1,1-0.3,1.4-0.7s0.8-0.9,1.2-1.6l2.1-3.9l1.4,0.8l-4.6,8.4l-1.2-0.7l0.7-1.4
						c-0.4,0.2-0.7,0.4-1.2,0.5C40.21,135,39.81,135,39.51,135z"/>
							<path className="st0" d="M37.21,126.7l0.4-0.5c0.1-0.2,0.3-0.4,0.3-0.5c0.1-0.1,0.1-0.3,0.1-0.5s0-0.4-0.1-0.6
						c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.3-0.2-0.6-0.4-0.9-0.5l0.8-1.1c0.3,0.1,0.7,0.3,1.2,0.6
						c0.3,0.2,0.5,0.4,0.7,0.6c0.3,0.3,0.5,0.6,0.7,1c0.1,0.3,0.2,0.7,0.1,1.1c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.3,0.5-0.5,0.8
						l-0.4,0.6l-2.7,3.8l1.6,1.1l-0.7,1.1l-1.6-1.1l-1.5,2.2l-1.3-0.9l1.5-2.2l-2.4-1.7l0.7-1.1l2.4,1.7L37.21,126.7z"/>
							<path className="st0" d="M26.61,111.8l-1.1,0.9c0.7-0.1,1.4,0,2,0.3s1.2,0.7,1.7,1.4c0.6,0.7,0.9,1.5,0.9,2.2c0,0.8-0.4,1.5-1,2.1
						c-0.5,0.4-1,0.7-1.6,0.7s-1.2-0.1-1.8-0.5c-0.5-0.3-0.9-0.6-1.4-1s-1-0.8-1.6-1.4l-0.6-0.5c-0.5,0.5-0.8,1-0.9,1.6
						s0.2,1.1,0.6,1.6c0.9,1,1.8,1.3,2.9,0.8l0.6,1.4c-0.8,0.3-1.6,0.4-2.4,0.2s-1.6-0.7-2.2-1.5c-0.6-0.7-1-1.4-1-2.2
						c-0.1-0.7,0.1-1.4,0.5-2.1c0.2-0.4,0.6-0.8,1.1-1.2l4.4-3.8L26.61,111.8z M23.11,115l0.6,0.5c0.5,0.4,0.9,0.8,1.2,1
						c0.3,0.3,0.6,0.5,1,0.7c0.9,0.6,1.6,0.6,2.2,0.1c0.4-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.9-0.6-1.3c-0.4-0.5-0.9-0.8-1.4-1
						s-1.1-0.2-1.6-0.1C24.51,114,23.81,114.4,23.11,115z"/>
							<path className="st0" d="M15.81,107.9c-0.4,0.4-0.6,0.8-0.6,1.3s0.2,1.1,0.5,1.6c0.3,0.5,0.7,0.8,1.1,1s0.8,0.2,1.1,0
						c0.3-0.2,0.4-0.4,0.5-0.7c0-0.3,0-0.7-0.1-1.1l-0.4-1.4l-0.1-0.4c-0.3-0.9-0.4-1.7-0.3-2.3s0.5-1.1,1.1-1.5
						c0.8-0.5,1.6-0.6,2.4-0.3s1.5,0.9,2.1,1.9s0.9,1.9,0.9,2.8c0,0.9-0.4,1.7-1.1,2.3l-1.1-1.2c0.4-0.4,0.6-0.9,0.6-1.5
						s-0.2-1.1-0.6-1.7c-0.3-0.5-0.7-0.8-1.1-1s-0.8-0.2-1.2,0.1c-0.3,0.2-0.5,0.5-0.5,0.8s0.1,0.8,0.2,1.3c0.1,0.2,0.1,0.5,0.2,0.8
						c0-0.1,0.1,0.3,0.3,1.2c0.5,1.5,0.2,2.6-0.9,3.3c-0.5,0.3-1,0.4-1.5,0.4c-0.5-0.1-1-0.3-1.5-0.6c-0.5-0.3-0.9-0.8-1.2-1.4
						c-0.6-0.9-0.8-1.8-0.8-2.7s0.4-1.6,1.1-2.2L15.81,107.9z"/>
							<path className="st0" d="M9.11,91c-0.5,0.3-0.8,0.8-0.9,1.3s-0.2,1.1,0,1.6c0.3,0.9,0.8,1.5,1.5,1.8c0.8,0.3,1.7,0.3,2.7,0
						s1.8-0.8,2.3-1.5s0.6-1.4,0.3-2.3c-0.3-1.2-1.1-1.8-2.3-1.9l-0.1-1.6c1,0.1,1.8,0.4,2.4,0.9c0.7,0.5,1.1,1.3,1.4,2.2
						s0.3,1.8,0.1,2.5c-0.2,0.8-0.6,1.4-1.3,2c-0.6,0.6-1.4,1-2.3,1.3c-1,0.3-1.9,0.3-2.7,0.2c-0.8-0.1-1.6-0.5-2.1-1
						c-0.6-0.5-1-1.3-1.3-2.2c-0.3-1-0.3-1.9,0-2.7s0.8-1.5,1.5-2L9.11,91z"/>
							<path className="st0" d="M14.11,84.9c-0.3,0.7-0.9,1.4-1.6,1.8c-0.7,0.5-1.6,0.8-2.5,0.9c-1,0.1-1.9,0.1-2.7-0.2
						c-0.8-0.3-1.5-0.7-2-1.4c-0.5-0.6-0.8-1.4-0.9-2.3s0-1.8,0.3-2.5s0.8-1.4,1.6-1.8c0.7-0.5,1.5-0.8,2.5-0.9s1.9-0.1,2.7,0.2
						c0.8,0.3,1.5,0.7,2,1.4c0.5,0.6,0.8,1.4,1,2.3C14.61,83.4,14.51,84.2,14.11,84.9z M6.61,81.3c-0.6,0.6-0.8,1.3-0.7,2.3
						c0.1,1,0.5,1.6,1.2,2s1.6,0.5,2.7,0.4s1.9-0.5,2.5-1.1s0.8-1.3,0.7-2.3c-0.1-1-0.5-1.6-1.2-2.1s-1.6-0.5-2.7-0.4
						C8.01,80.3,7.11,80.7,6.61,81.3z"/>
							<path className="st0" d="M6.71,68c0.7-0.2,1.6-0.2,2.6-0.2l4.6,0.2l-0.1,1.6l-4.6-0.2c-0.8,0-1.4,0-2,0.1s-1,0.4-1.3,0.8
						c-0.4,0.4-0.6,1-0.6,1.6s0.1,1.2,0.5,1.6s0.9,0.7,1.5,0.9c0.3,0.1,0.7,0.1,1.3,0.2l4.9,0.2l-0.1,1.6L3.81,76l0.1-1.4h1.2
						c-0.1-0.1-0.3-0.2-0.4-0.4c-0.3-0.3-0.5-0.7-0.7-1.1s-0.2-0.9-0.2-1.3c0-0.1,0-0.2,0-0.4c0-1,0.3-1.8,0.9-2.4
						C5.31,68.5,5.91,68.2,6.71,68z"/>
							<path className="st0" d="M6.31,56.8l8.7,5.3l-0.3,1.6l-10.1,1.6l0.3-1.6l7.9-1.2l-6.9-4.1L6.31,56.8z" />
							<path className="st0" d="M14.71,55.1c0.7-0.3,1.2-0.8,1.5-1.7c0.2-0.6,0.3-1.2,0.2-1.7c-0.1-0.5-0.4-1-0.8-1.5l1-1.2
						c0.7,0.6,1.1,1.4,1.3,2.2c0.2,0.9,0.1,1.7-0.2,2.6c-0.3,0.9-0.8,1.6-1.5,2.1c-0.7,0.5-1.4,0.8-2.2,0.9s-1.7,0-2.6-0.4
						c-1-0.4-1.7-0.8-2.3-1.4c-0.6-0.6-1-1.3-1.1-2.1c-0.2-0.8-0.1-1.6,0.2-2.5c0.5-1.4,1.4-2.3,2.6-2.7c1.2-0.4,2.6-0.2,4.1,0.4
						l-2.5,6.9C13.21,55.4,14.01,55.4,14.71,55.1z M9.51,53.1c0.3,0.6,0.8,1.1,1.6,1.5l1.9-5.3c-0.9-0.2-1.6-0.2-2.2,0.1
						c-0.6,0.3-1,0.8-1.3,1.6C9.21,51.8,9.21,52.5,9.51,53.1z"/>
							<path className="st0" d="M12.81,41.1c0.1-0.4,0.2-0.7,0.4-1c0.1-0.3,0.3-0.4,0.4-0.6l1.3,0.7c-0.2,0.2-0.3,0.4-0.4,0.6
						c-0.3,0.5-0.4,1-0.3,1.5s0.3,1,0.7,1.4c0.4,0.4,0.9,0.8,1.6,1.1l3.9,2.1l-0.7,1.4l-8.4-4.5l0.7-1.3l1.4,0.7
						c-0.2-0.3-0.4-0.7-0.5-1.2C12.71,41.8,12.71,41.5,12.81,41.1z"/>
							<path className="st0" d="M20.31,34.5c-0.5-0.2-1-0.3-1.5-0.1s-0.9,0.6-1.3,1.1c-0.3,0.5-0.5,0.9-0.5,1.4c0,0.4,0.1,0.8,0.4,1
						s0.5,0.2,0.8,0.2s0.6-0.3,1-0.6l1.1-0.9l0.3-0.3c0.8-0.6,1.4-1,2-1.2s1.2,0,1.8,0.4c0.8,0.5,1.2,1.2,1.2,2.1s-0.3,1.8-0.9,2.7
						c-0.6,0.9-1.4,1.6-2.2,1.9c-0.8,0.3-1.7,0.3-2.6-0.1l0.7-1.5c0.5,0.2,1.1,0.2,1.6,0s1-0.6,1.3-1.2c0.3-0.5,0.5-1,0.5-1.4
						c0-0.5-0.2-0.8-0.6-1.1c-0.3-0.2-0.6-0.3-1-0.2c-0.3,0.1-0.7,0.4-1.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.6c0.1,0-0.3,0.2-1,0.8
						c-1.2,1-2.4,1.2-3.3,0.5c-0.5-0.3-0.8-0.8-0.9-1.3s-0.1-1.1,0-1.6c0.1-0.6,0.4-1.1,0.8-1.7c0.6-0.9,1.3-1.5,2.2-1.8
						c0.8-0.3,1.6-0.3,2.4,0.1L20.31,34.5z"/>
							<path className="st0" d="M32.01,31.3l-1-1c0.1,0.7,0.1,1.4-0.1,2c-0.2,0.6-0.6,1.2-1.2,1.8c-0.6,0.7-1.4,1-2.1,1.1
						c-0.8,0.1-1.5-0.2-2.1-0.8c-0.5-0.4-0.7-1-0.8-1.5c-0.1-0.6,0-1.2,0.4-1.8c0.2-0.5,0.5-1,0.8-1.5s0.8-1.1,1.3-1.8l0.5-0.6
						c-0.6-0.5-1.1-0.7-1.7-0.7s-1.1,0.3-1.5,0.8c-0.9,1-1.1,1.9-0.6,2.9l-1.3,0.7c-0.4-0.8-0.5-1.5-0.4-2.4s0.6-1.6,1.3-2.4
						c0.6-0.7,1.3-1.1,2.1-1.2c0.7-0.2,1.4-0.1,2.2,0.3c0.4,0.2,0.8,0.5,1.3,0.9l4.2,4L32.01,31.3z M28.51,28.2l-0.5,0.6
						c-0.4,0.5-0.7,0.9-0.9,1.3c-0.2,0.3-0.4,0.7-0.6,1c-0.5,0.9-0.5,1.7,0.1,2.2c0.3,0.3,0.7,0.5,1.2,0.4c0.4,0,0.9-0.3,1.3-0.7
						c0.4-0.5,0.7-0.9,0.9-1.5c0.2-0.5,0.1-1.1,0-1.6C29.61,29.5,29.21,28.9,28.51,28.2z"/>
							<path className="st0" d="M34.11,24.7l0.4,0.5c0.2,0.2,0.3,0.4,0.4,0.5s0.3,0.2,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.1s0.4-0.1,0.6-0.3
						c0.1-0.1,0.2-0.2,0.4-0.3c0.3-0.2,0.5-0.5,0.8-0.7l0.8,1.1c-0.2,0.3-0.5,0.6-1,0.9c-0.3,0.2-0.5,0.4-0.8,0.5
						c-0.4,0.2-0.8,0.3-1.1,0.4c-0.4,0-0.7,0-1.1-0.2c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.4-0.4-0.7-0.7l-0.5-0.6l-2.9-3.6l-1.5,1.2
						l-0.8-1l1.5-1.2l-1.7-2.1l1.2-1l1.7,2.1l2.3-1.8l0.8,1l-2.3,1.8L34.11,24.7z"/>
							<path className="st0" d="M34.51,13.1l0.9,1.4l-1.3,0.9l-0.9-1.4L34.51,13.1z M36.31,15.9l5.2,8l-1.3,0.9l-5.2-8L36.31,15.9z" />
							<path className="st0" d="M44.81,21.6c-0.8-0.1-1.6-0.4-2.2-0.9s-1.2-1.2-1.7-2.1c-0.4-0.9-0.7-1.8-0.7-2.6s0.2-1.6,0.7-2.3
						s1.1-1.2,1.9-1.6c0.8-0.4,1.7-0.6,2.5-0.5s1.6,0.4,2.2,0.9s1.2,1.2,1.7,2.1c0.4,0.9,0.7,1.8,0.7,2.6c0,0.9-0.2,1.6-0.7,2.3
						c-0.4,0.7-1.1,1.2-1.9,1.7C46.41,21.5,45.61,21.7,44.81,21.6z M45.81,13.2c-0.7-0.3-1.5-0.3-2.4,0.1c-0.9,0.4-1.4,1-1.5,1.8
						s0,1.7,0.5,2.6c0.5,1,1.1,1.7,1.8,2c0.7,0.3,1.5,0.3,2.4-0.1c0.9-0.4,1.4-1,1.5-1.8c0.2-0.8,0-1.7-0.5-2.7
						C47.11,14.2,46.51,13.6,45.81,13.2z"/>
							<path className="st0" d="M58.41,9.1c0.4,0.6,0.7,1.4,1,2.4l1.3,4.4l-1.5,0.5l-1.3-4.4c-0.2-0.7-0.5-1.4-0.8-1.8s-0.7-0.8-1.1-1
						c-0.5-0.2-1.1-0.2-1.7,0s-1.1,0.5-1.4,1s-0.4,1-0.4,1.7c0,0.3,0.1,0.7,0.3,1.3l1.4,4.7l-1.5,0.5l-2.7-9.2l1.4-0.4l0.4,1.2
						c0.1-0.2,0.1-0.3,0.2-0.5c0.2-0.4,0.5-0.7,0.8-1c0.4-0.3,0.8-0.5,1.2-0.7c0.1,0,0.2-0.1,0.4-0.1c0.9-0.3,1.8-0.2,2.6,0.1
						C57.51,8,58.01,8.4,58.41,9.1z"/>
							<path className="st0" d="M67.41,8.2c-0.2-0.5-0.5-0.9-1-1.1c-0.5-0.2-1-0.3-1.7-0.2c-0.6,0.1-1,0.2-1.4,0.5s-0.5,0.6-0.4,1
						c0,0.3,0.2,0.5,0.4,0.7s0.6,0.3,1.1,0.4l1.4,0.2h0.5c1,0.1,1.7,0.4,2.3,0.7c0.5,0.3,0.8,0.9,0.9,1.6c0.1,0.9-0.1,1.7-0.8,2.3
						c-0.6,0.6-1.5,1-2.6,1.1s-2.1,0-2.9-0.3c-0.8-0.3-1.4-1-1.7-1.9l1.6-0.5c0.2,0.5,0.6,0.9,1.1,1.1c0.5,0.2,1.1,0.3,1.8,0.2
						c0.6-0.1,1.1-0.3,1.4-0.6c0.4-0.3,0.5-0.7,0.4-1.1c-0.1-0.4-0.2-0.7-0.5-0.8c-0.3-0.2-0.7-0.3-1.3-0.4c-0.2,0-0.5-0.1-0.9-0.1
						c0.1,0-0.4,0-1.3-0.2c-1.6-0.2-2.5-1-2.6-2.1c-0.1-0.6,0-1.1,0.3-1.5c0.3-0.5,0.7-0.8,1.2-1.1s1.1-0.5,1.8-0.5
						c1.1-0.1,2,0,2.8,0.4s1.3,1,1.5,1.9L67.41,8.2z"/>
							<path className="st0" d="M73.51,13v1.7h-1.7V13H73.51z" />
						</g>
						<g>
							<path className="st0" d="M116.69,33.03l-5.9-8.7c-0.14-0.18-0.32-0.27-0.58-0.27l-10.34,0.9c0,0-0.01,0-0.01,0
						c-0.8,0-1.41-0.31-1.85-0.9l-6.32-9.33l1.24-0.84l6.3,9.3c0.14,0.18,0.32,0.27,0.58,0.27l10.34-0.9c0,0,0.01,0,0.01,0
						c0.8,0,1.42,0.31,1.85,0.9l5.92,8.73L116.69,33.03z"/>
						</g>
					</g>
				</svg>
				<span className="contact__quote--talk">Lets talk.</span>
			</div>
			<div className="contact__text">
				<h4>
					<a href="tel:070319223006">07031 / 922 300 6</a><br />
					<a href="mailto:contact@simplycreative.de">contact@simplycreative.de</a><br />
					<a href="https://dribbble.com/simplycreative">Dribbble</a><br />
					<a href="https://www.linkedin.com/in/konrad-szuster-8a752526/">LinkedIn</a><br />
					<Link to="/Impressum">Impressum</Link>

				</h4>
			</div>
			<div className="contact__bottom">
				<a className="badge" href="https://api.badgr.io/public/assertions/1sV90bzWR2-D8xMpvVLV9g?identity__email=szuster.konrad%40outlook.com">
					<img className='tool-img' src={require('../gfx/GitLab_certified_associate.png')} alt="GitLab Certified Associate Badge"></img>
				</a>
			</div>
		</div>
	)
};

export default Contact;
