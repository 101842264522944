import React from 'react';
import { portfolioItems } from "./data";
import './Gallery.scss';

const Gallery = () => {
  return (
          <div className="gallery">
                  {portfolioItems.map(portfolioItem => (
                    <figure key={portfolioItem.id}>
                      <img
                          src={portfolioItem.imgSrc}
                          alt={portfolioItem.imgAlt}
                      />
                    </figure>
                  ))};
          </div>

  );
};

export default Gallery;
