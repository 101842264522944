import React from 'react';
import Hero from '../components/Hero'
import Seperator from '../components/Seperator'
import Gallery from '../components/Gallery'
import About from '../components/About'
import Contact from '../components/Contact'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Seperator triggerName="trigger-1" color="white" />
      <Gallery />
      <Seperator triggerName="trigger-2" color="white"/>
      <About />
      <Seperator triggerName="trigger-3" color="white"/>
      <Contact />
    </div>
  );
}

export default Home;
