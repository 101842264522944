import React, { useState } from 'react';

const divStyles = {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#0B0B0B',
    color: '#CDD7D6',
    paddingTop: '100px'
}

const Impressum = () => {
  useState(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div
      initial='initial'
      animate='animate'
      exit='exit'
      className='detail'
      style={divStyles}>
        <h1>Impressum</h1>
        <h4>Diensteanbieter</h4>
        <p>
            Konrad Szuster<br />
            In den Bergen 43<br />
            71101 Schönaich
        </p>
        <h4>Kontaktmöglichkeiten</h4>
        <p>
            E-Mail-Adresse: szuster.konrad@outlook.com<br />
            Telefon: 07031 / 922 300 6
        </p>
    </div>

  )
};

export default Impressum;