import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';

//Styling
import './App.scss';
import './base/variables.scss'

//Pages
import Home from './pages/Home';
import Impressum from './pages/Impressum';
// import NotFoundPage from './pages/NotFoundPage'


function App() {
  return (
    <div className="App">
      <div className="overlay">
        <a className="overlay__logo" href="/">
            <svg className="overlay__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 64">
            <defs />
            <g fill="#EA0A50" fillRule="evenodd">
              <path d="M32.78 23.4h-8.5c-.4 0-.8.26-1.05.66l-7.2 12.23c-.27.4-.27.93 0 1.32l7.2 12.23c.26.4.26.93 0 1.33l-6.55 10.9c-.52.8.13 1.86 1.05 1.86h8.5c.4 0 .8-.27 1.05-.67l7.2-12.22c.27-.4.27-.93 0-1.33l-7.2-12.23c-.26-.4-.26-.93 0-1.33l6.42-11.03c.52-.8 0-1.72-.92-1.72M20.08.8L.18 34.97c-.52.8.14 2 1.05 2h8.51c.4 0 .92-.28 1.05-.67L30.82 2.13c.4-.93-.13-1.99-1.18-1.99h-8.5c-.4 0-.8.27-1.06.67" />
            </g>
          </svg>
        </a>
        <div className="overlay__social">
          <a href="https://www.linkedin.com/in/konrad-szuster-8a752526/">
            <svg id="linkedin" xmlns="http://www.w3.org/2000/svg"viewBox="0 0 24 24">
              <g className="nc-icon-wrapper">
                <path d="M23,0H1C0.4,0,0,0.4,0,1v22c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0z M7.1,20.5H3.6V9h3.6 V20.5z M5.3,7.4c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1C7.4,6.5,6.5,7.4,5.3,7.4z M20.5,20.5h-3.6 v-5.6c0-1.3,0-3-1.8-3c-1.9,0-2.1,1.4-2.1,2.9v5.7H9.4V9h3.4v1.6h0c0.5-0.9,1.6-1.8,3.4-1.8c3.6,0,4.3,2.4,4.3,5.5V20.5z"/>
              </g>
            </svg>
          </a>
          <a href="https://dribbble.com/simplycreative">
            <svg id="dribbble" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <defs />
              <path fillRule="evenodd" clipRule="evenodd" d="M16 32C7.2 32 0 24.8 0 16S7.2 0 16 0s16 7.2 16 16-7.2 16-16 16zm13.5-13.8c-.5-.2-4.2-1.3-8.5-.6 1.8 4.9 2.5 8.9 2.7 9.7 3-2 5.2-5.3 5.8-9.1zm-8.2 10.4c-.2-1.2-1-5.4-2.9-10.4h-.1c-7.7 2.7-10.5 8-10.7 8.5 2.3 1.8 5.2 2.9 8.4 2.9 1.9.1 3.7-.3 5.3-1zM5.8 25.2c.3-.5 4.1-6.7 11.1-9 .2-.1.4-.1.5-.2-.3-.8-.7-1.6-1.1-2.3-6.8 2-13.4 2-14 1.9v.4c0 3.5 1.4 6.7 3.5 9.2zm-3.2-12c.6 0 6.2 0 12.6-1.7-2.3-4-4.7-7.4-5.1-7.9C6.4 5.5 3.5 9 2.6 13.2zM12.8 2.7c.4.5 2.9 3.9 5.1 8 4.9-1.8 6.9-4.6 7.2-4.9A13.8 13.8 0 0016 2.4c-1.1 0-2.2.1-3.2.3zm13.8 4.7c-.3.4-2.6 3.3-7.6 5.4.3.7.6 1.3.9 2 .1.2.2.5.3.7 4.5-.6 9.1.3 9.5.4-.1-3.2-1.2-6.2-3.1-8.5z" />
            </svg>
          </a>
        </div>
        <div className="overlay__info"> Made with passion.
              </div>
      </div>

      <Router>
      <AnimatePresence initial={true} exitBeforeEnter>
        <Route
          render={({ location }) => (

              <Switch location={location} >
                <Route
                  exact
                  path='/'
                  render={() => <Home />}
                />
                <Route path='/Impressum' component={Impressum}/>

                {/* <Route
                  exact
                  path='/Impressum'
                  render={() => <Impressum/>}
                />
                <Route component={Impressum} /> */}

              </Switch>


          )}
        />
 </AnimatePresence>
      </Router>

    </div>
  );
}

export default App;
