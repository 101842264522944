import React from 'react';
import './Seperator.scss';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

const Seperator = (props) => {
  return (
    <div className={'seperator ' + props.triggerName}>
      <Controller>
        <Scene duration={400} triggerElement={'.' + props.triggerName}>
          {(progress) => (
            <Timeline totalProgress={progress} paused>
              <Tween
                from={{
                  strokeDasharray: '384',
                  strokeDashoffset: '-384'
                }}
                to={{
                  strokeDasharray: '384',
                  strokeDashoffset: '0'
                }}
              >

                <svg className={'zigzag-animation ' + props.color}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 352">
                  <path d="M3.05 351.85V229.63c0-10.1 9.66-10.1 9.66-20.52 0-10.1-9.66-10.1-9.66-20.51 0-10.11 9.66-10.11 9.66-20.52 0-10.11-9.66-10.11-9.66-20.52 0-10.11 9.66-10.11 9.66-20.52 0-10.1-9.66-10.1-9.66-20.52 0-4.75 2.63-7.13 4.39-9.81l6.44-8.92c.58-.9.88-2.08.3-3.27L3.63 66.38c-.3-.9-.3-1.79 0-2.68l10.82-18.43c.3-.9.3-1.79 0-2.68L3.64 24.45c-.3-.9-.3-1.78 0-2.68L15.05 1.85" />
                </svg>

              </Tween>
            </Timeline>
          )}
        </Scene>
      </Controller>
    </div>
  )
};
export default Seperator;
