export const portfolioItems = [
  {
    id: 1,
    imgSrc: require('../gfx/portfolio/all/futuroma_elements.jpg'),
    imgAlt: 'Futuroma Corporate Design Elements'
  },
  {
    id: 2,
    imgSrc: require('../gfx/portfolio/all/futuroma_cards_horizontal.jpg'),
    imgAlt: 'Futuroma Business Cards'
  },
  {
    id: 3,
    imgSrc: require('../gfx/portfolio/all/froehlich_card_front.jpg'),
    imgAlt: 'Froehlich Solutions Business Card - Front'
  },
  {
    id: 4,
    imgSrc: require('../gfx/portfolio/all/froehlich_card_back.jpg'),
    imgAlt: 'Froehlich Solutions Business Card - Back'
  },
  {
    id: 5,
    imgSrc: require('../gfx/portfolio/all/Skully_dancer.png'),
    imgAlt: 'Skully Dancer - Character Illustration'
  },
  {
    id: 6,
    imgSrc: require('../gfx/portfolio/all/SmartLeadership.jpg'),
    imgAlt: 'Leadership App Screens'
  },
  {
    id: 7,
    imgSrc: require('../gfx/portfolio/all/Tommys_logo.jpeg'),
    imgAlt: `Tommy's Hairlounge - Logo`
  },
  {
    id: 8,
    imgSrc: require('../gfx/portfolio/all/Tommys_Visitenkarte.jpeg'),
    imgAlt: `Tommy's Hairlounge - Business Card`
  },
  {
    id: 9,
    imgSrc: require('../gfx/portfolio/all/Tommys_Terminkarte.jpeg'),
    imgAlt: `Tommy's Hairlounge - Appointment Card`
  },
  {
    id: 10,
    imgSrc: require('../gfx/portfolio/all/Autobahn_Home.png'),
    imgAlt: `Autobahn Touch Application`
  },
];
