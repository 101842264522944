import React from 'react';
import './Hero.scss';
import {
  Reveal, Tween, Timeline
} from 'react-gsap';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
gsap.registerPlugin(TextPlugin);

const Home = () => {
  return ( 
    <Reveal>
      <div className="hero">
        <Tween from={{ height: 0, opacity: 0, transform: 'skewY(3deg)' }} duration={2}>
          <h1 className="hero__intro-text" id="text">
            Hello, my name is <strong>Konrad Szuster.</strong><br />
            I am a Graphic Designer with a passion for
            <Timeline
                  target={<span> immersive visual experiences</span>}
                  repeat={2}
                >
                  <Tween
                    to={{ text: ' branding & brand development' }}
                    delay={4}
                    duration={2} />
                  <Tween
                    to={{ text: ' creative thinking & problem solving' }}
                    delay={4}
                    duration={2} />
                  <Tween
                    to={{ text: ' meaningful layouts & prototypes' }}
                    delay={4}
                    duration={2} />
                  <Tween
                    to={{ text: ' experience enhancing animations' }}
                    delay={4}
                    duration={2} />
                  <Tween
                    to={{ text: ' peak frontend development' }}
                    delay={4}
                    duration={2} />
                  <Tween
                    to={{ text: ' immersive visual experiences' }}
                    delay={4}
                    duration={2} />
            </Timeline>
          .
          </h1>
        </Tween>
      </div>
    </Reveal>
  )
};

export default Home;
